import { CPT, Diagnosis, ICD, Provider, Specialty } from '@getvim-os/types';
import { OSEhrState, OSEhrStateWithPII } from '@getvim/vim-os-api';
import { EHR } from '@getvim/vim-os-sdk-api';
import { EhrResourceEnhancementsExecutor } from '../ehrResourceEnhancements';
import { OSToAppEhrConvertor } from './interface';

const ICD_SYSTEM = 'supported ICD-10';
const CPT_SYSTEM = 'supported CPT-4';

type EHR_STATE = EHR.AppEhrState<{ withPII: true }>;

const pickSpeicalityDescription = (specialty: Specialty[]) =>
  specialty.reduce<string[]>((acc, s) => (s.description ? acc.concat(s.description) : acc), []);

const convertIcds = (icds: ICD[]): EHR.Diagnosis[] =>
  icds.reduce<EHR.Diagnosis[]>(
    (acc, d) =>
      d.code
        ? acc.concat({
            code: d.code,
            description: d.name,
            system: ICD_SYSTEM,
          })
        : acc,
    [],
  );
export const convertDiagnoses = (diagnosis: Diagnosis[]): EHR.Diagnosis[] =>
  diagnosis.reduce<EHR.Diagnosis[]>(
    (acc, d) =>
      d.code
        ? acc.concat({
            code: d.code,
            description: d.description,
            system: ICD_SYSTEM,
          })
        : acc,
    [],
  );

const convertCpts = (cpts: CPT[]): EHR.ProceduresCodes[] =>
  cpts.reduce<EHR.ProceduresCodes[]>(
    (acc, cpt) =>
      cpt.code
        ? acc.concat({
            code: cpt.code,
            description: cpt.name,
            system: CPT_SYSTEM,
          })
        : acc,
    [],
  );

const convertOrganization = (
  organization: NonNullable<Provider['organization']>,
): EHR.ProviderFacility => {
  const { ehrId, name, address, contact_info } = organization;
  return {
    facilityEhrId: ehrId,
    name,
    address,
    contact_info,
  };
};

const convertProvider = (provider: Provider): EHR.Provider => {
  const { providerEhrId, npi, demographics, organization, specialty, degree } = provider;
  const appProvider: EHR.Provider = {
    ehrProviderId: providerEhrId,
    npi,
    demographics,
    facility: organization && convertOrganization(organization),
    providerDegree: degree,
  };
  if (specialty) {
    appProvider.specialty = pickSpeicalityDescription(specialty);
  }
  return appProvider;
};

export class OsToExternalAppEhrConvertor implements OSToAppEhrConvertor<EHR_STATE> {
  getProblemList = async (
    vimPatientId: string | undefined,
    ehrResourceEnhancementsExecutor: EhrResourceEnhancementsExecutor<EHR_STATE>,
  ) => {
    if (!vimPatientId) {
      throw new Error('vimPatientId is undefined! unable to call getProblemList');
    }
    return await ehrResourceEnhancementsExecutor.getProblemList(vimPatientId);
  };

  convertPatientWithPII = (
    osPatient: NonNullable<OSEhrStateWithPII['patient']>,
    ehrResourceEnhancementsExecutor: EhrResourceEnhancementsExecutor<EHR_STATE>,
  ): EHR.Patient => {
    const { patientId, vimPatientId, mrn, demographics, insurance, pcp, contact_info } = osPatient;
    const { address, ...otherDemographics } = demographics || { address: undefined };

    const appPatient: EHR.Patient = {
      identifiers: {
        ehrPatientId: patientId,
        vimPatientId,
        mrn,
      },
      contact_info,
      demographics: otherDemographics,
      address,
      getProblemList: () =>
        this.getProblemList(appPatient.identifiers?.vimPatientId, ehrResourceEnhancementsExecutor),
    };

    if (insurance) {
      const { ehrInsurance, groupId, payerId, memberId } = insurance;
      appPatient.insurance = {
        ehrInsurance,
        groupId,
        payerId,
        memberId,
      };
    }
    if (pcp) {
      appPatient.pcp = convertProvider(pcp);
    }

    return appPatient;
  };

  convertReferralWithPII = (
    osReferral: NonNullable<OSEhrStateWithPII['referral']>,
  ): NonNullable<EHR.Referral> => {
    const {
      referralId,
      vimReferralId,
      specialty,
      diagnosis,
      targetProvider,
      referringProvider,
      startDate,
      endDate,
      createdDate,
      status,
      priority,
      numberOfVisits,
      cpts,
      facilityName,
      authCode,
      isLocked,
      reasons,
      notes,
    } = osReferral;
    return {
      identifiers: {
        ehrReferralId: referralId,
        vimReferralId,
      },
      basicInformation: {
        specialty: specialty?.description,
        startDate,
        endDate,
        createdDate,
        status,
        priority,
        numberOfVisits: numberOfVisits ? Number(numberOfVisits) : undefined,
        facilityName,
        authCode,
        isLocked,
        reasons,
        notes,
      },
      targetProvider: targetProvider && convertProvider(targetProvider),
      referringProvider: referringProvider && convertProvider(referringProvider),
      conditions: {
        diagnosis: diagnosis && convertDiagnoses(diagnosis),
      },
      procedureCodes: {
        cpts: cpts && convertCpts(cpts),
      },
    };
  };

  convertEncounter = (osEncounter: NonNullable<OSEhrState['encounter']>): EHR.Encounter => {
    const {
      encounterId,
      encounterDate,
      provider,
      isLocked,
      soapAssessments,
      subjective,
      objective,
      plan,
    } = osEncounter;
    return {
      identifiers: { ehrEncounterId: encounterId },
      provider: provider && convertProvider(provider),
      assessment: {
        generalNotes: soapAssessments?.generalNotes,
        diagnosisCodes:
          soapAssessments?.diagnosisCodes && convertDiagnoses(soapAssessments.diagnosisCodes),
      },
      subjective: {
        chiefComplaintNotes: subjective?.chiefComplaintNotes,
        historyOfPresentIllnessNotes: subjective?.historyOfPresentIllnessNotes,
        reviewOfSystemsNotes: subjective?.reviewOfSystemsNotes,
        generalNotes: subjective?.generalNotes,
      },
      objective: {
        generalNotes: objective?.generalNotes,
        physicalExamNotes: objective?.physicalExamNotes,
      },
      basicInformation: {
        status: isLocked === true ? 'LOCKED' : isLocked === false ? 'UNLOCKED' : undefined,
        encounterDateOfService: encounterDate,
      },
      plan: {
        procedureCodes: plan?.procedureCodes && convertCpts(plan.procedureCodes),
        generalNotes: plan?.generalNotes,
      },
    };
  };

  convertOrders = (osOrders: NonNullable<OSEhrState['orders']>): EHR.Order[] => {
    return osOrders.map<EHR.Order>((order) => {
      const { id, encounterId, cpts, icd, createdDate, type } = order;
      return {
        identifiers: {
          ehrOrderId: id,
        },
        basicInformation: {
          type,
          ehrEncounterId: encounterId,
          createdDate,
        },
        assessments: {
          assessments: icd && convertIcds(icd),
        },
        procedureCodes: {
          procedureCodes: cpts && convertCpts(cpts),
        },
      };
    });
  };

  constructor() {}
  convertEhr: OSToAppEhrConvertor<EHR_STATE>['convertEhr'] = (
    { patient, encounter, referral, orders },
    ehrResourceEnhancementsExecutor,
  ) => {
    return {
      patient: patient && this.convertPatientWithPII(patient, ehrResourceEnhancementsExecutor),
      referral: referral && this.convertReferralWithPII(referral),
      encounter: encounter && this.convertEncounter(encounter),
      orders: orders && this.convertOrders(orders),
    };
  };
}
