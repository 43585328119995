import React from 'react';
import { Banner, BannerTypes, ColorNameEnum, Text } from '@getvim/atomic-ui';
import { formatDateToLocalHours } from '../../../formatters';
import './TabContentBanner.less';

export type TabContentBannerProps = {
  reviewBefore: string;
};

const TabContentBanner: React.FC<TabContentBannerProps> = ({
  reviewBefore,
}: TabContentBannerProps) => {
  const bannerMessage = (
    <span>
      <Text align="text-left">
        You have charts that have been requested for extraction. Please review and reject as needed.
        <br />
        Charts will otherwise be approved and shared daily by {formatDateToLocalHours(
          reviewBefore,
        )}{' '}
        (your local timezone) by the "Review by date" dates.{' '}
        <a
          href="https://9056483.fs1.hubspotusercontent-na1.net/hubfs/9056483/Vim%20Data%20Exchange/Vim%20Data%20Exchange%20Walkthrough.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <b>Learn more</b>
        </a>
      </Text>
    </span>
  );

  return (
    <div className="patient-request-banner-container">
      <Banner
        type={BannerTypes.INFORMATION}
        text={bannerMessage}
        icon={<i className="icon-exclamation-c" />}
        textColor={ColorNameEnum.ultraDark}
      ></Banner>
    </div>
  );
};

export default TabContentBanner;
