import { useQuery } from '@tanstack/react-query';
import { MS_SLEEP_BETWEEN_FIND_PATIENT_REQUESTS } from '../../../consts';
import { BffApi } from '../../../api';
import { ExtendedPatientRequestsInput } from '../../../api/bff';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect } from 'react';
dayjs.extend(utc);

export const useFindExtendedPatientRequestsQuery = ({
  queryKey,
  input,
  isAppOpen,
  currentTab,
  shouldUseGetAppEnableStatus,
}: {
  queryKey: string;
  input?: Partial<ExtendedPatientRequestsInput>;
  isAppOpen?: boolean;
  currentTab: string;
  shouldUseGetAppEnableStatus: boolean;
}) => {
  const { data, isFetching, refetch, dataUpdatedAt, isError } = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      if (shouldUseGetAppEnableStatus && !isAppOpen) {
        return null;
      }
      return BffApi.findExtendedPatientRequests(input);
    },
    refetchOnWindowFocus: false,
    refetchInterval: () => (isAppOpen ? false : MS_SLEEP_BETWEEN_FIND_PATIENT_REQUESTS),
    enabled: false,
  });

  useEffect(() => {
    if (currentTab === queryKey && isAppOpen && shouldUseGetAppEnableStatus) {
      void refetch(); // Trigger the query manually when isAppOpen becomes true
    }
  }, [currentTab, isAppOpen, queryKey, refetch, shouldUseGetAppEnableStatus]);

  return {
    data,
    isFetching,
    refetch,
    dataUpdatedAt: dayjs(dataUpdatedAt).local().format('HH:mm:ss'),
    isError,
  };
};
