import { analyticsManager } from './analyticsManager';

export const trackAppEnabled = ({
  requests,
  userRoles,
  applicationVersion,
}: {
  requests: {
    openRequestsTotalResults: number;
    allRequestsTotalResults: number;
  };
  userRoles: string[];
  applicationVersion: number;
}): void => {
  analyticsManager.track('cde_app_enabled', {
    app_source_displayed: analyticsManager.getDatasource(),
    // removed data_requester from the event as it is not used in this event
    app_notifications_count: requests.openRequestsTotalResults ? 1 : 0,
    items_received_count: requests.openRequestsTotalResults + requests.allRequestsTotalResults,
    open_items_count: requests.openRequestsTotalResults,
    other_items_count: requests.allRequestsTotalResults,
    user_roles: userRoles,
    application_version: applicationVersion,
  });
};
