import 'reflect-metadata';
import { ApplicationManifest } from '@getvim-os/types';
import {
  EnableLaunchButtonPayload,
  TrackPayload,
  AppToOSMessageTypes,
  HandshakePayload,
} from '@getvim/vim-os-api';
import { VimOsLogger } from '@getvim-os/logger';
import { CommonVimSDK, InternalResourceUpdater } from '@getvim/dynamic-vim-os-sdk/sdk';
import { GENERIC_SDK } from '@getvim/vim-os-sdk-api';
import { AppEhrState } from '../types';
import { EhrActionsDispatcher } from './ehrActions';
import { EhrEventsApi } from './ehrEventsApi';
import { InternalOsCommunicator } from './internalOsCommunicator';
import { InternalSessionContext, InternalSessionContextApi } from './internalSessionContextApi';
import { OsToInternalAppEhrConvertor } from './osToInternalAppEhrConvertor';
import { SharedServiceApi } from './sharedServiceApi';
import { VimEventTarget } from './vimEventTarget';
import { LaunchButtonApi } from './launchButtonApi';

type EHR_STATE = AppEhrState<{ withPII: true }>;

export class InternalVimSDK extends CommonVimSDK<EHR_STATE> implements GENERIC_SDK<EHR_STATE> {
  constructor(
    payload: HandshakePayload,
    osMessageChannel: MessageChannel,
    logger: VimOsLogger,
    tokensResponse?: { idToken: string },
  ) {
    super({
      payload,
      osMessageChannel,
      oSToAppEhrConvertor: new OsToInternalAppEhrConvertor(),
      osCommunicator: new InternalOsCommunicator(payload, osMessageChannel),
    });
    const osCommunicator = new InternalOsCommunicator(payload, osMessageChannel);
    const incomingOsMessagePort = osMessageChannel.port1;
    this.manifestSupport = payload.manifestSupport;
    this.deviceId = payload.deviceId;
    this.bareboneType = payload.bareboneType;
    this.bareboneVersion = payload.bareboneVersion;
    this.hostname = payload.hostname;
    this.internalEvents = new EventTarget() as unknown as VimEventTarget;
    this.sessionContext = new InternalSessionContextApi(
      payload,
      incomingOsMessagePort,
      this.internalEvents,
      tokensResponse,
    );
    this.sharedService = new SharedServiceApi(incomingOsMessagePort, this.internalEvents);
    this.ehrEvents = new EhrEventsApi(incomingOsMessagePort, this.internalEvents);
    this.launchButton = new LaunchButtonApi(osCommunicator, incomingOsMessagePort);
    this.ehrActions = new EhrActionsDispatcher(osCommunicator, payload.manifestSupport);
    this.internalResourceUpdater = new InternalResourceUpdater(
      osMessageChannel.port1,
      osCommunicator,
      logger,
    );
    this.trackAnalytics = (payload: TrackPayload) => {
      osCommunicator.sendMessageToOS({
        type: AppToOSMessageTypes.TRACK_ANALYTICS,
        payload,
      });
    };
    this.enableLaunchButtons = (enabledLaunchButtons: EnableLaunchButtonPayload) => {
      osCommunicator.sendMessageToOS({
        type: AppToOSMessageTypes.ENABLE_LAUNCH_BUTTONS,
        payload: enabledLaunchButtons,
      });
    };
  }
  public trackAnalytics: (payload: TrackPayload) => void;
  public manifestSupport: ApplicationManifest;

  public internalResourceUpdater: InternalResourceUpdater;
  public internalEvents: VimEventTarget;
  public ehrActions: EhrActionsDispatcher;
  public sharedService: SharedServiceApi;
  public ehrEvents: EhrEventsApi;
  public launchButton: LaunchButtonApi;
  public declare sessionContext: InternalSessionContext;
  /**
   * @deprecated use launchButton instead
   */
  public enableLaunchButtons: (enabledLaunchButtons: EnableLaunchButtonPayload) => void;
  public deviceId: string;
  public bareboneType: string;
  public bareboneVersion: string;
  public hostname?: string;
}
