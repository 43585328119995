import 'reflect-metadata';
import { EHR, GENERIC_SDK } from '@getvim/vim-os-sdk-api';
import { SETTINGS_SDK } from '@getvim/vim-os-settings-sdk-api';

import {
  AppToOSMessageTypes,
  OAuthHandshakePayload,
  HandshakeMetadata,
  HandshakePayload,
  OSToAppMessageEvent,
  OSToAppMessageTypes,
  AppHandshakeError,
} from '@getvim/vim-os-api';

import { CommonVimSDK, OsToExternalAppEhrConvertor } from './sdk';
import { UnauthorizedError } from '@getvim-os/errors';
import { getSdkMetadata } from './metadata';
import { Logger } from '@getvim-os/logger';
import { getLogger } from './logger';
import { InitializeVimSDKOptions } from './types';

type EHR_STATE = EHR.AppEhrState<{ withPII: true }>;

type SDK = GENERIC_SDK<EHR.EHR_STATE_TEMPLATE> | SETTINGS_SDK;

export class ExternalSDK extends CommonVimSDK<EHR_STATE> {
  constructor(
    payload: HandshakePayload,
    osMessageChannel: MessageChannel,
    logger: Logger,
    tokensResponse?: { idToken: string },
  ) {
    super({
      payload,
      osMessageChannel,
      oSToAppEhrConvertor: new OsToExternalAppEhrConvertor(),
      logger,
      tokensResponse,
    });
  }
}

let sdkPromise: Promise<SDK> | undefined;
let sdk: SDK | undefined;

export const initializeVimSDK = <SDKImpl extends SDK = ExternalSDK>(
  options: InitializeVimSDKOptions<SDKImpl> = {},
): Promise<SDKImpl> => {
  const logger = options.logger ?? getLogger();
  if (!sdkPromise) {
    sdkPromise = new Promise<SDKImpl>((resolve, reject) => {
      (async () => {
        const sdkMetadata = getSdkMetadata();
        const appHandshakeTraceId = Math.random().toString(16).slice(2);

        let appUrl: string | undefined;
        try {
          const appUrlObject = new URL(window.location.href);
          appUrlObject.search = '';
          appUrl = appUrlObject.href;
        } catch {
          /* empty */
        }

        logger.updateMetadata({
          ...(sdkMetadata || {}),
          appHandshakeTraceId,
          appUrl,
        });

        logger.info('Starting application handshake with vim-os.', {
          sdkMetadata,
          appHandshakeTraceId,
          appUrl,
        });

        const { accessToken, idToken } = (await generateAppTokens(options, logger)) || {};

        const messageChannel = new MessageChannel();
        messageChannel.port1.addEventListener('message', (message: OSToAppMessageEvent) => {
          switch (message?.data?.type) {
            case OSToAppMessageTypes.APP_HANDSHAKE_ACK: {
              const handshakePayload = message.data.payload;

              const {
                userSessionId,
                deviceId,
                manifestSupport,
                organization,
                bareboneType,
                bareboneVersion,
                adapterId,
                hostname,
              } = handshakePayload;

              logger.updateMetadata({
                userSessionId,
                deviceId,
                adapterName: adapterId,
                appName: manifestSupport.metadata.name,
                appId: manifestSupport.metadata.id,
              });

              logger.info('Got handshake response from vim-os.', {
                handshakePayload: {
                  manifestSupport,
                  organization,
                  bareboneVersion,
                  bareboneType,
                  hostname,
                },
              });

              const tokensResponse = idToken ? { idToken } : undefined;

              sdk = options.sdkFactory
                ? options.sdkFactory(handshakePayload, messageChannel, logger, tokensResponse)
                : new ExternalSDK(handshakePayload, messageChannel, logger, tokensResponse);
              resolve(sdk as SDKImpl);
              break;
            }
            case OSToAppMessageTypes.HEALTH_CHECK: {
              message.ports[0].postMessage({
                error: false,
              });
              break;
            }
            case OSToAppMessageTypes.APP_HANDSHAKE_ERROR: {
              logger.error('Recived error in handshake.', {
                handshakeErrorPayload: message.data.payload,
              });

              reject(message.data.payload);
              break;
            }
          }
        });
        messageChannel.port1.start();

        if (isUsingNonVersionedSDK(sdkMetadata)) {
          console.info(
            "It looks like you're using an unversioned URL for the Vim OS SDK script tag. For better stability and compatibility, please use a versioned URL.\n" +
              'A versioned URL should look like this: https://connect.getvim.com/vim-os-sdk/v0.0.x/vim-sdk.umd.js\n' +
              'If you are using NPM, upgrading your package should resolve this issue.\n' +
              'For more information, please refer to the documentation: https://docs.getvim.com/vim-os-js/getting-started.html',
          );
        }

        const handshakeMetadata: HandshakeMetadata = {
          ...(sdkMetadata || {}),
          appHandshakeTraceId,
          appUrl,
        };

        if (!accessToken) {
          logger.info('Sending application handshake initial message - legacy flow.');
          window.parent.postMessage(
            {
              type: AppToOSMessageTypes.APP_HANDSHAKE,
              payload: {
                metadata: handshakeMetadata,
              },
            },
            '*',
            [messageChannel.port2],
          );
        } else {
          logger.info('Sending application handshake initial message - oAuth flow.');
          const payload: OAuthHandshakePayload = {
            token: accessToken,
            metadata: handshakeMetadata,
          };
          window.parent.postMessage(
            {
              type: AppToOSMessageTypes.APP_OAUTH_HANDSHAKE,
              payload,
            },
            '*',
            [messageChannel.port2],
          );
        }
      })().catch((error) => {
        logger.error('Error initializing sdk.', { error });

        if (error instanceof UnauthorizedError) {
          const payload: AppHandshakeError = {
            code: 'authorization_error',
            data: error.message,
          };

          window.parent.postMessage({
            type: AppToOSMessageTypes.APP_HANDSHAKE_ERROR,
            payload,
          });
        } else {
          const payload: AppHandshakeError = {
            code: 'internal_error',
            data: error.toString(),
          };

          window.parent.postMessage({
            type: AppToOSMessageTypes.APP_HANDSHAKE_ERROR,
            payload,
          });
        }

        reject(error);
      });
    });
  }
  if (sdk) {
    logger.info('SDK already initialized, returning existing instance.');
    Promise.resolve(sdk as SDKImpl);
  }
  logger.info('Initializing SDK');
  return sdkPromise as Promise<SDKImpl>;
};

const safeParseResponse = async (response: Response): Promise<TokenResponse> => {
  try {
    return await response.json();
  } catch {
    return {};
  }
};

const convertTokenResponse = ({
  access_token,
  accessToken,
  token,
  idToken,
  id_token,
}: TokenResponse): { accessToken?: string; idToken?: string; token?: string } => {
  return { accessToken: access_token || accessToken || token, idToken: idToken || id_token };
};

const generateAppTokens = async (
  options: InitializeVimSDKOptions<any>,
  logger: Logger,
): Promise<{ accessToken: string; idToken?: string } | undefined> => {
  if (options.accessToken) {
    return { accessToken: options.accessToken };
  }

  const code = new URL(window.location.href).searchParams.get('code');
  const encodedTokenEndpoint = new URL(window.location.href).searchParams.get('token_endpoint');
  const accessTokenStorageKey = `vim-os_app-accesstoken_${code}`;
  const idTokenStorageKey = `vim-os_app-idtoken_${code}`;
  try {
    const accessToken = window.sessionStorage.getItem(accessTokenStorageKey);
    const idToken = window.sessionStorage.getItem(idTokenStorageKey) || undefined;

    if (accessToken) {
      logger.info('Using existing cached app token.');
      return { accessToken, idToken };
    }
  } catch {
    /* empty */
  }

  if (!code) {
    logger.info('No auth code found, App working in legacy mode');
    return;
  }

  if (!options.appTokenEndpoint && !encodedTokenEndpoint) {
    logger.error('sdk initialized without token endpoint');
    throw new Error('Token endpoint is required, please define it in application manifest.');
  }

  let response: Response;
  try {
    const appTokenEndpoint = new URL(
      options.appTokenEndpoint
        ? options.appTokenEndpoint
        : decodeURIComponent(encodedTokenEndpoint as string),
    );
    response = await fetch(appTokenEndpoint.toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
      }),
    });
  } catch (error_) {
    const error = error_ as Error;
    logger.error('Failed to get app token', { appTokenEndpoint: options.appTokenEndpoint }, error);
    throw new UnauthorizedError('Failed to get app token', {
      text: error?.message || 'Failed to get app token',
    });
  }

  const result = await safeParseResponse(response);
  if (response.status >= 300 || response.status < 200) {
    logger.error('Failed to get app token', {
      text: response.statusText,
      result,
    });
    throw new UnauthorizedError('Failed to get app token', {
      text: response.statusText,
      result,
    });
  }

  const { accessToken, idToken } = convertTokenResponse(result);

  if (!accessToken) {
    logger.error("App token endpoint didn't return token", {
      result,
    });
    throw new UnauthorizedError("App token endpoint didn't return token", {
      result,
    });
  }

  try {
    window.sessionStorage.setItem(accessTokenStorageKey, accessToken);
    window.sessionStorage.setItem(idTokenStorageKey, idToken || '');
  } catch {
    /* empty */
  }
  logger.info('Successfully retrieved jwt token from application.');
  return { accessToken, idToken };
};

const isUsingNonVersionedSDK = (metadata: HandshakeMetadata | undefined): boolean => {
  const noExternalSDKVersion: boolean = !metadata?.externalSDKVersion;
  const isLoadedWithScriptTag: boolean = !!metadata?.sdkScriptUrl;
  const isLatestScriptTag: boolean = !!metadata?.sdkScriptUrl?.includes(
    'https://connect.getvim.com/vim-os-sdk/vim-sdk.umd.js',
  );

  return noExternalSDKVersion && isLoadedWithScriptTag && isLatestScriptTag;
};

type TokenResponse = {
  accessToken?: string;
  idToken?: string;
  token?: string;
  access_token?: string;
  id_token?: string;
};
