import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ButtonGroupComponent } from '@getvim/atomic-ui';
import { Tab } from '../../types';
import { appLogger } from '../App';
import AllRequestsTab from './AllRequestsTab';
import OpenRequestsTab from './OpenRequestsTab';
import './Tabs.less';
import {
  UseFindExtendedPatientRequestsResponse,
  useGetPatientRequestsData,
} from '../../hooks/api/patient-request/useGetPatientRequestsData';
import { useAppState } from '../../stores/AppState.store';
import { AppStateActionType } from '../../stores/appState.types';
import { useNotification } from '../../hooks/useNotification';
import { trackAppEnabled } from '../../analytics/trackAppEnabled';
import { trackTabSwitch } from '../../analytics/trackTabSwitch';
import { ModifyAppViewStatePayload, useModifyAppState } from '../../hooks/useModifyAppState';
import { useGetEnableStatusData } from '../../hooks';
import TabContentLoader from './tab-content-loader/TabContentLoader';

const TabContext: Record<Tab, React.FC<UseFindExtendedPatientRequestsResponse>> = {
  [Tab.OpenRequestsTab]: OpenRequestsTab,
  [Tab.AllRequestsTab]: AllRequestsTab,
};

const Tabs = ({
  userRoles,
  applicationVersion,
  shouldUseGetAppEnableStatus,
}: {
  userRoles: string[];
  applicationVersion: number;
  shouldUseGetAppEnableStatus: boolean;
}) => {
  const {
    dispatch,
    state: { openRequestsTotal, allRequestsTotal },
  } = useAppState();
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.OpenRequestsTab);
  const [appEnabled, setAppEnabled] = useState<boolean>(false);

  const { appEnableStatusData } = useGetEnableStatusData(shouldUseGetAppEnableStatus);
  const { openRequests, allRequests } = useGetPatientRequestsData(
    shouldUseGetAppEnableStatus,
    currentTab,
  );

  const { notify } = useNotification();

  const { modifyAppState } = useModifyAppState();

  const openRequestsTotalModified = useMemo(() => {
    if (openRequests.data?.extendedPatientRequestsDetails) {
      return openRequests.data?.extendedPatientRequestsDetails?.length
        ? openRequests.data?.paginationResult.totalResults
        : 0;
    } else if (shouldUseGetAppEnableStatus && !appEnableStatusData.isFetching) {
      return appEnableStatusData.data?.toReviewRequestsCount ?? 0;
    }
    return 0;
  }, [
    openRequests.data?.extendedPatientRequestsDetails,
    openRequests.data?.paginationResult.totalResults,
    shouldUseGetAppEnableStatus,
    appEnableStatusData.isFetching,
    appEnableStatusData.data?.toReviewRequestsCount,
  ]);

  const allRequestsTotalModified = useMemo(() => {
    if (allRequests.data?.extendedPatientRequestsDetails) {
      return allRequests.data?.extendedPatientRequestsDetails?.length
        ? allRequests.data?.paginationResult.totalResults
        : 0;
    } else if (shouldUseGetAppEnableStatus && !appEnableStatusData.isFetching) {
      return appEnableStatusData.data?.historicRequestsCount ?? 0;
    }

    return 0;
  }, [
    allRequests.data?.extendedPatientRequestsDetails,
    allRequests.data?.paginationResult.totalResults,
    shouldUseGetAppEnableStatus,
    appEnableStatusData.isFetching,
    appEnableStatusData.data?.historicRequestsCount,
  ]);

  useEffect(() => {
    dispatch({
      type: AppStateActionType.SET_OPEN_REQUESTS_TOTAL,
      payload: openRequestsTotalModified,
    });
  }, [dispatch, openRequestsTotalModified]);

  useEffect(() => {
    dispatch({
      type: AppStateActionType.SET_ALL_REQUESTS_TOTAL,
      payload: allRequestsTotalModified,
    });
  }, [dispatch, allRequestsTotalModified]);

  useEffect(() => {
    const totalResults = openRequestsTotal + allRequestsTotal;
    const shouldEnableAppOnHub: boolean = totalResults > 0;

    const appState: ModifyAppViewStatePayload = {
      enable: shouldEnableAppOnHub,
      ...(shouldEnableAppOnHub && { notifications: openRequestsTotal }),
    };
    modifyAppState(appState);
    appLogger.debug('modified hub app state', { appState });

    if (shouldEnableAppOnHub) {
      if (!appEnabled) {
        trackAppEnabled({
          requests: {
            openRequestsTotalResults: openRequestsTotal,
            allRequestsTotalResults: allRequestsTotal,
          },
          userRoles,
          applicationVersion,
        });
        setAppEnabled(true);
      }

      if (
        shouldUseGetAppEnableStatus &&
        !appEnableStatusData.isFetching &&
        appEnableStatusData.data &&
        appEnableStatusData.data.toReviewRequestsCount > 0
      ) {
        appLogger.debug('notify with shouldUseGetAppEnableStatus', {
          toReviewBefore: appEnableStatusData.data?.toReviewBefore,
        });
        notify(appEnableStatusData.data?.toReviewBefore);
      } else if (
        !shouldUseGetAppEnableStatus &&
        openRequests?.data?.extendedPatientRequestsDetails.length
      ) {
        appLogger.debug('notify without shouldUseGetAppEnableStatus', {
          toReviewBefore: openRequests.data.extendedPatientRequestsDetails[0]?.toReviewBefore,
        });
        notify(openRequests.data.extendedPatientRequestsDetails[0]?.toReviewBefore);
      }
    }
  }, [
    modifyAppState,
    openRequestsTotal,
    allRequestsTotal,
    notify,
    openRequests.data,
    allRequests.data,
    shouldUseGetAppEnableStatus,
    appEnableStatusData.isFetching,
    appEnableStatusData.data,
    appEnabled,
    userRoles,
    applicationVersion,
  ]);

  const handleTabChange = useCallback(
    (tab: Tab) => {
      appLogger.debug('CDE tab clicked', { tab });
      setCurrentTab(tab);

      if (openRequests?.data && allRequests?.data) {
        trackTabSwitch({
          requests: { openRequests: openRequests.data, allRequests: allRequests.data },
          redirectedFrom: currentTab,
          tabName: tab,
        });
      }
    },
    [allRequests.data, currentTab, openRequests.data],
  );

  const getTabContent = () => (currentTab === Tab.OpenRequestsTab ? openRequests : allRequests);
  const TabComponent = TabContext[currentTab];

  return (
    <>
      {appEnableStatusData.isFetching ? (
        <TabContentLoader />
      ) : (
        <>
          <div className="tabs-wrap">
            <ButtonGroupComponent
              className="tabs"
              items={[
                {
                  text: `${Tab.OpenRequestsTab} (${openRequestsTotal ?? 0})`,
                  value: Tab.OpenRequestsTab,
                },
                {
                  text: `${Tab.AllRequestsTab} (${allRequestsTotal ?? 0})`,
                  value: Tab.AllRequestsTab,
                },
              ]}
              value={currentTab}
              buttonType="tiny"
              bgColor="groupOptionOutline"
              direction="row"
              onChange={handleTabChange}
              noGaps
            />
          </div>
          <TabComponent {...getTabContent()} />
        </>
      )}
    </>
  );
};

export default Tabs;
