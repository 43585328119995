import React, { useCallback, useEffect } from 'react';
import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { ApplicationSize } from '@getvim/internal-vim-os-sdk/types';
import { ColorNameEnum, SizeEnum, Text } from '@getvim/atomic-ui';
import { ModifyAppViewStatePayload, useModifyAppState } from '../../hooks/useModifyAppState';
import './RegularUser.less';
import { useJobPullInfo } from '../../hooks/useJobPullInfo';
import { trackAppEnabled } from '../../analytics/trackAppEnabled';
import { trackActionClicked } from '../../analytics/trackActionClicked';

const RegularUser = ({ userRoles }: { userRoles: string[] }) => {
  const { setDynamicAppSize } = useApi();
  const { modifyAppState } = useModifyAppState();

  const isJobPulling = useJobPullInfo();

  useEffect(() => {
    const appState: ModifyAppViewStatePayload = {
      enable: true,
      notifications: isJobPulling ? 1 : 0,
    };
    modifyAppState(appState);
    setDynamicAppSize(ApplicationSize.CLASSIC);
  }, [modifyAppState, setDynamicAppSize, isJobPulling]);

  useEffect(() => {
    trackAppEnabled({
      userRoles,
      applicationVersion: 2,
      requests: {
        openRequestsTotalResults: 0,
        allRequestsTotalResults: 0,
      },
    });
  }, [userRoles]);

  const handleActionClicked = useCallback(() => {
    trackActionClicked({ action_type: 'read more' });
  }, []);

  return (
    <div className="regular-user-container">
      <div>
        <p className="text-with-href text-ultra-dark empty-state-title">
          Your organization has enabled Vim's Clinical Data Exchange (CDE) Application to streamline
          patient chart retrieval. Please note that this notification indicates that Vim is actively
          retrieving Clinical Data through your EHR session. You can read more about the CDE
          Application and its benefits{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://9056483.fs1.hubspotusercontent-na1.net/hubfs/9056483/Enablement/CDE Admin & Non-Admin Users App Explanation.pdf#page=5"
            onClick={handleActionClicked}
          >
            here
          </a>
        </p>
      </div>

      <img
        className="desktop-chart-image"
        src="https://static.getvim.com/img/desktopChart.svg"
        alt=""
      />

      <div>
        <Text
          colorName={ColorNameEnum.dark}
          text="If you do not wish to participate in the Clinical Data Exchange retrieval process, please contact your administrator or Vim."
          size={SizeEnum['12px']}
          className="empty-state-title"
        />
      </div>
    </div>
  );
};

export default RegularUser;
