import { useQuery } from '@tanstack/react-query';
import { BffApi } from '../../../api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { MS_SLEEP_BETWEEN_FIND_PATIENT_REQUESTS } from '../../../consts';
dayjs.extend(utc);

export interface GetAppEnableStatusInput {
  reviewTimestamp: Date;
}

export const useGetAppEnableStatusQuery = ({
  queryKey,
  input,
  isAppOpen,
  shouldUseGetAppEnableStatus,
}: {
  queryKey: string;
  input: GetAppEnableStatusInput;
  isAppOpen?: boolean;
  shouldUseGetAppEnableStatus?: boolean;
}) => {
  const { data, isFetching, refetch, dataUpdatedAt } = useQuery({
    queryKey: [queryKey],
    queryFn: () => {
      if (shouldUseGetAppEnableStatus) {
        return BffApi.getAppEnableStatus(input);
      }
      return null;
    },
    refetchOnWindowFocus: false,
    refetchInterval: () => (isAppOpen ? false : MS_SLEEP_BETWEEN_FIND_PATIENT_REQUESTS),
  });

  return {
    data,
    isFetching,
    refetch,
    dataUpdatedAt: dayjs(dataUpdatedAt).local().format('HH:mm:ss'),
  };
};
