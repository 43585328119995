import React from 'react';
import { formatPatientRequestsTable } from '../../formatters';
import AllRequestsTable from '../table/AllRequestsTable';
import TabContentEmpty from './tab-content-empty/TabContentEmpty';
import TabContentError from './tab-content-error/TabContentError';
import TabContentLoader from './tab-content-loader/TabContentLoader';
import { UseFindExtendedPatientRequestsResponse } from '../../hooks/api/patient-request/useGetPatientRequestsData';
import TableActionsBar from './TableActionsBar';
import { appLogger } from '../App';

const AllRequestsTab: React.FC<UseFindExtendedPatientRequestsResponse> = ({
  data,
  setCurrentPage,
  currentPage,
  isFetching,
  dataUpdatedAt,
  refetch,
  isError,
}: UseFindExtendedPatientRequestsResponse) => {
  if (isFetching) return <TabContentLoader />;

  if (isError) {
    appLogger.warning('error received on AllRequestsTab component, showing error tab', {
      data,
      isFetching,
      currentPage,
      dataUpdatedAt,
    });
    return <TabContentError />;
  }
  if (!data || data.extendedPatientRequestsDetails.length === 0) return <TabContentEmpty />;

  return (
    <>
      <div className="table-top-header">
        <TableActionsBar
          paging={{
            currentPage,
            setCurrentPage,
            isFetching,
            totalResults: data?.paginationResult.totalResults,
          }}
          dataUpdatedAt={dataUpdatedAt}
          refetch={refetch}
        />
      </div>

      {isFetching ? (
        <TabContentLoader />
      ) : (
        <div className="patient-requests-table-container">
          <AllRequestsTable
            rows={formatPatientRequestsTable(data.extendedPatientRequestsDetails)}
          />
        </div>
      )}
    </>
  );
};

export default AllRequestsTab;
