import { JobFinishDetails, FinishJobError } from '../../api/bff';
import {
  FAILED_TO_PRINT_ENCOUNTER_ERROR_MESSAGE,
  NO_PATIENT_ERROR_MESSAGE,
  DATA_RESTRICTED_ERROR_MESSAGE,
  FIND_PATIENT_UNEXPECTED_ISSUE,
  ENCOUNTER_NOT_LOCKED_ERROR_MESSAGE,
  INVALID_WORKER,
} from '../../consts';

export const isFinishJobErrorIsValid = (errorMessage: string): FinishJobError => {
  switch (errorMessage) {
    case NO_PATIENT_ERROR_MESSAGE: {
      return { message: JobFinishDetails.PATIENT_DOESNT_EXIST, isValid: true };
    }
    case DATA_RESTRICTED_ERROR_MESSAGE: {
      return { message: JobFinishDetails.DATA_RESTRICTED, isValid: true };
    }
    case ENCOUNTER_NOT_LOCKED_ERROR_MESSAGE: {
      return { message: JobFinishDetails.ENCOUNTER_NOT_LOCKED, isValid: true };
    }
    case FAILED_TO_PRINT_ENCOUNTER_ERROR_MESSAGE: {
      return { message: JobFinishDetails.PRINT_FAILED, isValid: false };
    }
    case FIND_PATIENT_UNEXPECTED_ISSUE: {
      return { message: JobFinishDetails.FIND_PATIENT_FAILED, isValid: false };
    }
    case INVALID_WORKER: {
      return { message: JobFinishDetails.INVALID_WORKER, isValid: false };
    }
    default:
      return { message: JobFinishDetails.UNEXPECTED_ISSUE, isValid: false };
  }
};
