import { gql } from '@apollo/client';
import { CdeGqlClientManager } from './gqlClient';

export enum RejectFinishStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export interface RejectPatientRequestsInput {
  patientRequestIds: number[];
}

const REJECT_PATIENT_REQUESTS_MUTATION = gql`
  mutation rejectPatientRequests($input: RejectPatientRequestsInput!) {
    rejectPatientRequests(input: $input)
  }
`;

export async function rejectPatientRequests(input: RejectPatientRequestsInput): Promise<boolean> {
  try {
    const gqlClient = await CdeGqlClientManager.getClient();

    await gqlClient.mutate<object, { input: RejectPatientRequestsInput }>({
      mutation: REJECT_PATIENT_REQUESTS_MUTATION,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return true;
  } catch {
    return false;
  }
}
