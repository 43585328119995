import { useAppState } from '@getvim/internal-vim-os-sdk/react';
import { ExtendedPatientRequestsDetailsResponse } from '../../../api/bff';
import { TABLE_PAGE_SIZE } from '../../../consts';
import { APPROVAL_STATUS, ORDER_BY, SORT_BY, Tab } from '../../../types';
import { useCallback, useEffect, useState } from 'react';
import { useFindExtendedPatientRequestsQuery } from '.';
import { trackChangePage } from '../../../analytics/trackChangePage';

export interface UseFindExtendedPatientRequestsResponse {
  data?: ExtendedPatientRequestsDetailsResponse;
  isFetching?: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  dataUpdatedAt: string;
  refetch: () => void;
  isError: boolean;
}

export interface ExtendedPatientRequestsData {
  openRequests: UseFindExtendedPatientRequestsResponse;
  allRequests: UseFindExtendedPatientRequestsResponse;
}

export const useGetPatientRequestsData = (
  shouldUseGetAppEnableStatus: boolean,
  currentTab: Tab,
): ExtendedPatientRequestsData => {
  const [openRequestsCurrentPage, setOpenRequestsCurrentPage] = useState<number>(1);
  const [allRequestsCurrentPage, setAllRequestsCurrentPage] = useState<number>(1);

  const { isAppOpen } = useAppState();
  const currentDate = new Date();

  const openRequests = useFindExtendedPatientRequestsQuery({
    queryKey: Tab.OpenRequestsTab,
    input: {
      pagination: {
        pageSize: TABLE_PAGE_SIZE,
        pageNumber: openRequestsCurrentPage,
        sortBy: SORT_BY.REVIEW_BEFORE,
        orderBy: ORDER_BY.ASC,
      },
      query: {
        approvalStatuses: [
          {
            status: APPROVAL_STATUS.AUTO_APPROVED,
            filter: {
              toReviewBeforeRange: {
                from: currentDate,
              },
            },
          },
        ],
      },
    },
    isAppOpen,
    currentTab,
    shouldUseGetAppEnableStatus,
  });
  const { refetch: openRequestsRefetch, ...openRequestsRest } = openRequests;

  const allRequests = useFindExtendedPatientRequestsQuery({
    queryKey: Tab.AllRequestsTab,
    input: {
      pagination: {
        pageSize: TABLE_PAGE_SIZE,
        pageNumber: allRequestsCurrentPage,
        sortBy: SORT_BY.UPDATE_TIME,
        orderBy: ORDER_BY.DESC,
      },
      query: {
        approvalStatuses: [
          { status: APPROVAL_STATUS.REJECTED },
          {
            status: APPROVAL_STATUS.AUTO_APPROVED,
            filter: {
              toReviewBeforeRange: {
                to: currentDate,
              },
            },
          },
        ],
      },
    },
    isAppOpen,
    currentTab,
    shouldUseGetAppEnableStatus,
  });
  const { refetch: allRequestsRefetch, ...allRequestsRest } = allRequests;

  useEffect(() => {
    if (!openRequestsRefetch) return;

    void openRequestsRefetch();
  }, [openRequestsCurrentPage, openRequestsRefetch]);

  useEffect(() => {
    if (!allRequestsRefetch) return;

    void allRequestsRefetch();
  }, [allRequestsCurrentPage, allRequestsRefetch]);

  const refetchAllData = useCallback(() => {
    void openRequestsRefetch();
    void allRequestsRefetch();
  }, [openRequestsRefetch, allRequestsRefetch]);

  const onOpenRequestsCurrentPage = (page: number) => {
    setOpenRequestsCurrentPage(page);
    handleChangePage(openRequestsCurrentPage, page);
  };

  const onAllRequestsCurrentPage = (page: number) => {
    setAllRequestsCurrentPage(page);
    handleChangePage(allRequestsCurrentPage, page);
  };

  const handleChangePage = (currentPage: number, pageClicked: number) => {
    if (openRequests?.data && allRequests?.data) {
      trackChangePage({
        requests: {
          openRequests: openRequests?.data,
          allRequests: allRequests?.data,
        },
        currentPage,
        pageClicked,
      });
    }
  };

  return {
    openRequests: {
      ...openRequestsRest,
      data: openRequests.data ?? undefined,
      setCurrentPage: onOpenRequestsCurrentPage,
      currentPage: openRequestsCurrentPage,
      refetch: shouldUseGetAppEnableStatus ? openRequestsRefetch : refetchAllData,
    },
    allRequests: {
      ...allRequestsRest,
      data: allRequests.data ?? undefined,
      setCurrentPage: onAllRequestsCurrentPage,
      currentPage: allRequestsCurrentPage,
      refetch: shouldUseGetAppEnableStatus ? allRequestsRefetch : refetchAllData,
    },
  };
};
