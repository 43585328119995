import { Logger } from '@getvim-os/logger';
import { OSToAppMessageEvent, OSToAppMessageTypes, UpdatableEhrFields } from '@getvim/vim-os-api';
import { OsCommunicator } from '..';
import { IInternalResourceUpdater } from '../../types/ehr-updates/builders';
import { Subscription } from '../subscription';
import EncounterUpdateBuilder from './encounterUpdateBuilder';
import ReferralUpdateBuilder from './referralUpdateBuilder';

export class InternalResourceUpdater
  extends Subscription<{ updatableFields: UpdatableEhrFields }>
  implements IInternalResourceUpdater
{
  #updatableFields: UpdatableEhrFields = {};

  get updatableFields() {
    return this.#updatableFields;
  }

  constructor(
    incomingOsMessagePort: MessagePort,
    private osCommunicator: OsCommunicator,
    private logger?: Logger,
  ) {
    super({ updatableFields: {} });
    incomingOsMessagePort.addEventListener('message', (message: OSToAppMessageEvent) => {
      if (message.data.type === OSToAppMessageTypes.EHR_STATE_CHANGE) {
        const { updatableFields } = message.data.payload;
        this.#updatableFields = updatableFields || {};
        this.dispatch('updatableFields', this.#updatableFields);
      }
    });
  }

  public encounterBuilder() {
    return new EncounterUpdateBuilder(this.osCommunicator, this.logger);
  }

  public referralBuilder() {
    return new ReferralUpdateBuilder(this.osCommunicator, this.logger);
  }
}
