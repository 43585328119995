import { useAppState } from '@getvim/internal-vim-os-sdk/react';
import { GetAppEnableStatusResponse } from '../../../api/bff';
import { useGetAppEnableStatusQuery } from '.';

export interface UseGetAppEnableStatusResponse {
  data?: GetAppEnableStatusResponse;
  isFetching?: boolean;
  dataUpdatedAt: string;
  refetch: () => void;
}

export interface AppEnableStatusData {
  appEnableStatusData: UseGetAppEnableStatusResponse;
}

export const useGetEnableStatusData = (
  shouldUseGetAppEnableStatus: boolean,
): AppEnableStatusData => {
  const { isAppOpen } = useAppState();
  const currentDate = new Date();

  const appEnableStatus = useGetAppEnableStatusQuery({
    queryKey: 'getAppEnableStatus',
    input: {
      reviewTimestamp: currentDate,
    },
    isAppOpen,
    shouldUseGetAppEnableStatus,
  });
  const { refetch, ...appEnableStatusRest } = appEnableStatus;

  return {
    appEnableStatusData: {
      ...appEnableStatusRest,
      data: appEnableStatus.data ?? undefined,
      refetch,
    },
  };
};
