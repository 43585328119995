import { ActionNames } from '@getvim/internal-vim-os-sdk/types';
import {
  NO_PATIENT_ERROR_MESSAGE,
  FIND_PATIENT_UNEXPECTED_ISSUE,
  INVALID_WORKER,
} from '../../../consts';
import { getLogger } from '../../../components/logger';
import { jobManager, sessionManager } from '../../../state';
import { JobsCacheManager } from '../../../utils/jobsCacheManager';
import { adapterActionApi } from '../../../api/adapter';
import { featureFlagsClient } from '../../../services';
import { FindPatientResponse } from '../../../types';
import { patientEncountersHandler } from './logic';

const cache = new JobsCacheManager<object, string | object>();

export const getPatientEncountersHandler = async () => {
  const shouldReportSessionInactive = await featureFlagsClient.getFlag({
    flagName: 'shouldReportSessionInactive',
    defaultValue: false,
  });

  const getPatientEncountersLogger = getLogger({ scope: 'get-patient-encounters-handler' });

  const jobPatient = jobManager.get('patient');

  let ehrPatientId: string | undefined = undefined;
  let ehrPatient;

  ehrPatientId = cache.get(jobPatient) as string;

  if (!ehrPatientId) {
    const {
      isSuccess: isActionSuccessful,
      patientId,
      isInvalidWorker,
    }: FindPatientResponse = (await adapterActionApi.findPatient(jobPatient)) || {};

    getPatientEncountersLogger.info(`Got response for action: ${ActionNames.FIND_PATIENT}`, {
      ehrPatientId: patientId,
      isSuccess: isActionSuccessful,
      isInvalidWorker: isInvalidWorker,
    });

    if (!isActionSuccessful) {
      if (isInvalidWorker && shouldReportSessionInactive) {
        sessionManager.set({ invalidEhrSession: true });
        throw new Error(INVALID_WORKER);
      }
      throw new Error(FIND_PATIENT_UNEXPECTED_ISSUE);
    }

    if (isActionSuccessful && !patientId) {
      throw new Error(NO_PATIENT_ERROR_MESSAGE);
    }

    const foundPatientId: string = patientId as string;
    cache.set(jobPatient, foundPatientId);
    ehrPatientId = foundPatientId;
  }

  ehrPatient = cache.get(ehrPatientId);

  if (!ehrPatient) {
    const patient = await adapterActionApi.getPatient(ehrPatientId);

    getPatientEncountersLogger.info(`Got response for action: ${ActionNames.GET_PATIENT}`, {
      ehrPatient: patient,
    });
    if (!patient) throw new Error(NO_PATIENT_ERROR_MESSAGE);

    cache.set(ehrPatientId, patient);

    ehrPatient = patient;
  } else {
    jobManager.set({ vimPatientId: ehrPatient.vimPatientId });
  }

  return await patientEncountersHandler({ ehrPatient });
};
