import { gql } from '@apollo/client';
import { CdeGqlClientManager } from './gqlClient';
import { getLogger } from '../../components/logger';

export interface GetAppEnableStatusResponse {
  toReviewBefore: string;
  toReviewRequestsCount: number;
  historicRequestsCount: number;
}

export interface GetAppEnableStatusInput {
  reviewTimestamp: Date;
}

const GET_APP_ENABLED_STATUS_QUERY = gql`
  query getAppEnableStatus($input: GetAppEnableStatusInput!) {
    getAppEnableStatus(input: $input) {
      toReviewBefore
      toReviewRequestsCount
      historicRequestsCount
    }
  }
`;

export async function getAppEnableStatus(
  input: GetAppEnableStatusInput,
): Promise<GetAppEnableStatusResponse | undefined> {
  const logger = getLogger({ scope: `get-app-enable-status-api` });
  const gqlClient = await CdeGqlClientManager.getClient();
  logger.info('requesting getAppEnableStatus', { input });
  const result = await gqlClient.query<
    { getAppEnableStatus: GetAppEnableStatusResponse },
    { input: GetAppEnableStatusInput }
  >({
    query: GET_APP_ENABLED_STATUS_QUERY,
    variables: { input },
    fetchPolicy: 'no-cache',
  });
  logger.info('received response from getAppEnableStatus', { result });

  const { toReviewBefore, toReviewRequestsCount, historicRequestsCount } =
    result.data?.getAppEnableStatus ?? {};

  return {
    toReviewBefore,
    toReviewRequestsCount,
    historicRequestsCount,
  };
}
