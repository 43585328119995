import { VimOsLogger } from '@getvim-os/logger';
import { AppStoreServiceId, EHR_EVENT } from '@getvim-os/types';
import { HandshakePayload, VimAuth } from '@getvim/vim-os-api';
import { EHR, Hub } from '@getvim/vim-os-sdk-api';
import React, { useEffect, useMemo, useState } from 'react';
import { initializeVimSDK } from '../';
import { InternalVimSDK } from '../sdk/internalSdk';
import {
  AppEhrState,
  InternalSDKToAppMessageTypes,
  SDKToAppEventListener,
  SDKToAppMessageServiceTypes,
  SHARED_SERVICE_DATA,
} from '../types';
import {
  ReactVimApi,
  VimSDKApiContext,
  VimSDKAppStateContext,
  VimSDKResourcesContext,
  VimSdkAuthContext,
  VimSdkSharedServiceStateContext,
  VimSdkUserMetadataContext,
} from './store';
import { InitializeVimSDKOptions } from '@getvim/dynamic-vim-os-sdk/types';

type EHR_STATE = AppEhrState<{ withPII: true }>;

const VimSDKProviderWrapper: React.FC<{
  sdk: InternalVimSDK;
}> = ({ children, sdk }) => {
  const [ehrState, setEhrState] = useState<EHR_STATE>(sdk.ehr.ehrState ?? {});
  const [sharedServicesState, setSharedServicesState] = useState<
    Record<AppStoreServiceId, SHARED_SERVICE_DATA>
  >({});
  const [ehrEvent, setEhrEvent] = useState<EHR_EVENT>();
  const [vimAuth, setVimAuth] = useState<VimAuth>({});
  useEffect(() => {
    if (sdk.sessionContext?.vimAuth) {
      setVimAuth(sdk.sessionContext?.vimAuth);
    }
  }, [sdk.sessionContext?.vimAuth]);
  const [appState, setAppState] = useState<Hub.AppStateData>(
    sdk.hub.appState ?? { isAppOpen: false },
  );
  const user = sdk.sessionContext.user;
  const organization = sdk.sessionContext?.organization;
  const manifestSupport = sdk.manifestSupport;
  const ehrVendor = sdk.sessionContext.ehrType;
  const [deviceId, setDeviceId] = useState<string>(sdk.deviceId);
  const [bareboneType, setBareboneType] = useState<string | undefined>(sdk.bareboneType);
  const [bareboneVersion, setBareboneVersion] = useState<string | undefined>(sdk.bareboneVersion);
  const [hostname, setHostname] = useState<string | undefined>(sdk.hostname);
  const [userSessionId, setUserSessionId] = useState<string>(sdk.sessionContext.sessionId);

  useEffect(() => {
    setUserSessionId(sdk.sessionContext.sessionId);
  }, [sdk.sessionContext.sessionId]);

  useEffect(() => {
    setDeviceId(sdk.deviceId);
  }, [sdk.deviceId]);

  useEffect(() => {
    setBareboneType(sdk.bareboneType);
  }, [sdk.bareboneType]);

  useEffect(() => {
    setBareboneVersion(sdk.bareboneVersion);
  }, [sdk.bareboneVersion]);

  useEffect(() => {
    setHostname(sdk.hostname);
  }, [sdk.hostname]);

  useEffect(() => {
    const onPatientChange = (patient: EHR_STATE[typeof EHR.EHRResource.patient]) => {
      setEhrState((current) => ({ ...current, patient }));
    };
    const onEncounterChange = (encounter: EHR_STATE[typeof EHR.EHRResource.encounter]) => {
      setEhrState((current) => ({ ...current, encounter }));
    };
    const onReferralChange = (referral: EHR_STATE[typeof EHR.EHRResource.referral]) => {
      setEhrState((current) => ({ ...current, referral }));
    };
    const onOrdersChange = (orders: EHR_STATE[typeof EHR.EHRResource.orders]) => {
      setEhrState((current) => ({ ...current, orders }));
    };
    const ehrEventListener: SDKToAppEventListener<InternalSDKToAppMessageTypes.ON_EHR_EVENT> = (
      event,
    ) => {
      setEhrEvent(event.detail);
    };

    const onSharedServiceEvent = <T extends keyof SHARED_SERVICE_DATA>(
      serviceId: AppStoreServiceId,
      key: T,
      data: SHARED_SERVICE_DATA[T],
    ) => {
      setSharedServicesState((current) => {
        const serviceState = current[serviceId] || {};
        return {
          ...current,
          [serviceId]: {
            ...serviceState,
            [key]: data,
          },
        };
      });
    };
    const serviceEnrichedEhrEventListener: SDKToAppEventListener<
      SDKToAppMessageServiceTypes.SERVICE_ENRICHED_EHR
    > = (event) => {
      const { serviceId, resource, enrichedData } = event.detail;
      setSharedServicesState((current) => {
        const serviceState = current[serviceId] || {};
        const enrichedEHR = serviceState.enrichedEHR || {};
        return {
          ...current,
          [serviceId]: {
            ...serviceState,
            enrichedEHR: {
              ...enrichedEHR,
              [resource]: enrichedData,
            },
          },
        };
      });
    };
    const serviceEnrichedUserListener: SDKToAppEventListener<
      SDKToAppMessageServiceTypes.SERVICE_ENRICHED_USER
    > = (event) => {
      const { serviceId, user } = event.detail;
      onSharedServiceEvent(serviceId, 'enrichedUser', { user });
    };
    const serviceCustomMessageListener: SDKToAppEventListener<
      SDKToAppMessageServiceTypes.SERVICE_CUSTOM_MESSAGE
    > = (event) => {
      const { serviceId, data } = event.detail;
      onSharedServiceEvent(serviceId, 'customMessage', data);
    };
    const onAuthTokenSet: SDKToAppEventListener<InternalSDKToAppMessageTypes.AUTH_TOKEN_SET> = (
      event,
    ) => {
      setVimAuth((current) => ({
        ...current,
        ...((event.detail as any) ?? {}),
      }));
    };
    const onSelectedAppChanged = (newAppState?: Hub.AppStateSubscription) => {
      setAppState((current) => ({ ...current, isAppOpen: newAppState?.isAppOpen }));
    };

    const onAppSessionChange = (newAppSessionId?: string) => {
      setAppState((current) => ({ ...current, appSessionId: newAppSessionId }));
    };

    sdk.internalEvents.addEventListener(
      SDKToAppMessageServiceTypes.SERVICE_ENRICHED_EHR,
      serviceEnrichedEhrEventListener,
    );
    sdk.internalEvents.addEventListener(
      SDKToAppMessageServiceTypes.SERVICE_ENRICHED_USER,
      serviceEnrichedUserListener,
    );
    sdk.internalEvents.addEventListener(
      SDKToAppMessageServiceTypes.SERVICE_CUSTOM_MESSAGE,
      serviceCustomMessageListener,
    );
    sdk.hub.appState.subscribe('appOpenStatus', onSelectedAppChanged);
    sdk.hub.appState.subscribe('appSessionId', onAppSessionChange);
    sdk.ehr.subscribe(EHR.EHRResource.patient, onPatientChange);
    sdk.ehr.subscribe(EHR.EHRResource.encounter, onEncounterChange);
    sdk.ehr.subscribe(EHR.EHRResource.referral, onReferralChange);
    sdk.ehr.subscribe(EHR.EHRResource.orders, onOrdersChange);
    sdk.internalEvents.addEventListener(
      InternalSDKToAppMessageTypes.ON_EHR_EVENT,
      ehrEventListener,
    );
    sdk.internalEvents.addEventListener(
      InternalSDKToAppMessageTypes.AUTH_TOKEN_SET,
      onAuthTokenSet,
    );
    return () => {
      sdk.internalEvents.removeEventListener(
        SDKToAppMessageServiceTypes.SERVICE_ENRICHED_EHR,
        serviceEnrichedEhrEventListener,
      );
      sdk.internalEvents.removeEventListener(
        SDKToAppMessageServiceTypes.SERVICE_ENRICHED_USER,
        serviceEnrichedUserListener,
      );
      sdk.internalEvents.removeEventListener(
        SDKToAppMessageServiceTypes.SERVICE_CUSTOM_MESSAGE,
        serviceCustomMessageListener,
      );
      sdk.hub.appState.unsubscribe('appOpenStatus', onSelectedAppChanged);
      sdk.hub.appState.unsubscribe('appSessionId', onAppSessionChange);
      sdk.internalEvents.removeEventListener(
        InternalSDKToAppMessageTypes.ON_EHR_EVENT,
        ehrEventListener,
      );
      sdk.ehr.unsubscribe(EHR.EHRResource.patient, onPatientChange);
      sdk.ehr.unsubscribe(EHR.EHRResource.encounter, onEncounterChange);
      sdk.ehr.unsubscribe(EHR.EHRResource.referral, onReferralChange);
      sdk.ehr.unsubscribe(EHR.EHRResource.orders, onOrdersChange);

      sdk.internalEvents.removeEventListener(
        InternalSDKToAppMessageTypes.AUTH_TOKEN_SET,
        onAuthTokenSet,
      );
    };
  }, [sdk.internalEvents, sdk]);

  const resources = useMemo(() => ({ ehrState, ehrEvent }), [ehrState, ehrEvent]);
  const metadata = useMemo(
    () => ({
      user,
      organization,
      manifestSupport,
      ehrVendor,
      deviceId,
      bareboneType,
      bareboneVersion,
      hostname,
      userSessionId,
    }),
    [
      user,
      organization,
      manifestSupport,
      ehrVendor,
      deviceId,
      bareboneType,
      bareboneVersion,
      hostname,
      userSessionId,
    ],
  );

  const bindedSdkApi = useMemo<ReactVimApi>(
    () => ({
      ehrActions: sdk.ehrActions,
      resourceUpdate: sdk.internalResourceUpdater,
      resourceUpdater: sdk.ehr.resourceUpdater,
      pushNotification: sdk.hub.pushNotification,
      autoPopup: sdk.hub.autoPopup.bind(sdk),
      copyToClipboard: sdk.utils.copyToClipboard.bind(sdk),
      setActivationStatus: sdk.hub.setActivationStatus.bind(sdk),
      setNotificationsIndicator: sdk.hub.notificationBadge.set.bind(sdk),
      clearNotificationsIndicator: sdk.hub.notificationBadge.hide.bind(sdk),
      setTooltipText: sdk.hub.setTooltipText.bind(sdk),
      setDynamicAppSize: sdk.hub.setDynamicAppSize.bind(sdk),
      trackAnalytics: sdk.trackAnalytics.bind(sdk),
      enableLaunchButtons: sdk.enableLaunchButtons.bind(sdk),
      launchButton: sdk.launchButton,
      subscribeAppOpenStatus: (callback) => {
        sdk.hub.appState.subscribe('appOpenStatus', callback);
        return () => {
          sdk.hub.appState.unsubscribe('appOpenStatus', callback);
        };
      },
      sessionContext: sdk.sessionContext,
      closeApp: sdk.hub.closeApp,
    }),
    [sdk],
  );

  return (
    <VimSDKResourcesContext.Provider value={resources}>
      <VimSdkAuthContext.Provider value={vimAuth}>
        <VimSDKApiContext.Provider value={bindedSdkApi}>
          <VimSDKAppStateContext.Provider value={appState}>
            <VimSdkUserMetadataContext.Provider value={metadata}>
              <VimSdkSharedServiceStateContext.Provider value={sharedServicesState}>
                {children}
              </VimSdkSharedServiceStateContext.Provider>
            </VimSdkUserMetadataContext.Provider>
          </VimSDKAppStateContext.Provider>
        </VimSDKApiContext.Provider>
      </VimSdkAuthContext.Provider>
    </VimSDKResourcesContext.Provider>
  );
};

export const VimSDKProvider: React.FC<InitializeVimSDKOptions> = ({
  children,
  ...initializeOptions
}) => {
  const [sdk, setSdk] = useState<InternalVimSDK | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (!sdk) {
        setSdk(
          await initializeVimSDK({
            sdkFactory: (
              payload: HandshakePayload,
              osMessageChannel: MessageChannel,
              logger: VimOsLogger,
            ) => new InternalVimSDK(payload, osMessageChannel, logger),
            appTokenEndpoint: initializeOptions.appTokenEndpoint,
            accessToken: initializeOptions.accessToken,
          }),
        );
      }
    })();
  }, [sdk, initializeOptions]);

  if (!sdk) {
    return null;
  }
  return (
    <VimSDKProviderWrapper sdk={sdk} {...initializeOptions}>
      {children}
    </VimSDKProviderWrapper>
  );
};
